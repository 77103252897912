export default {
    Tous: 'Tous',
    sci_marketview_sale: 'Taq Auto - Vente',
    sci_marketview_service: 'Taq Auto - Service',
    easydeal: 'Easy Deal',
    easy_deal: 'Easy Deal',
    activix: 'Activix',
    autopropulsion: 'Autopropulsion',
    quicktrade: 'Torque',
    vauto: 'vAuto',
    gmcanada: 'GM Canada',
    lucy: 'Lucy',
    mbca_digital: 'Mercedes Canada',
    fca_digital: 'Fiat Chrysler Canada',
    porsche_digital: 'Porsche Canada',
    polaris_digital: 'Polaris Canada',
    kia_digital: 'Kia Canada',
    harleyDavidsonDigital: 'Harley Davidson Canada',
    harley_davidson_digital: 'Harley Davidson Canada',
    nci_digital: 'Nissan-Infiniti Canada',
    nissan_digital: 'Nissan-Infiniti Canada',
    cdk_service_appt: 'CDK - Service',
    cdk_rv_service: 'CDK - Service',
    one_eighty: '1-80',
    serti_service: 'Serti - Service',
    serti_rv_service: 'Serti - Service',
    ctwizard: 'CTWizard',
    ct_wizard: 'CTWizard',
    forddirect: 'Ford Canada',
    ford_direct: 'Ford Canada',
    deskit: 'Deskit',
    scraper: 'Intégrateur',
    serti_vente: 'Serti - Vente',
    serti_sale: 'Serti - Vente',
    stellantis_digital: 'Stellantis Digital',
    dealer_vu: 'DealerVu',
    pbs: 'PBS',
    pbsService: 'PBS - Service',
    pbs_service: 'PBS - Service',
    pbsWorkOrder: 'PBS - Work Order',
    pbs_work_order: 'PBS - Work Oder',
    maserati_digital: 'Maserati Digital',
    one_eighty_inventory: 'One-Eighty - Inventaire',
    cdk_inventory: 'CDK - Inventaire',
    serti_inventory: 'Serti - Inventaire',
    pbs_inventory: 'PBS - Inventaire',
    easydeal_inventory: 'EasyDeal - Inventaire',
    merlin: 'Merlin',
    serti_renewal: 'Serti - Renouvellement',
    bmwCanada: 'BMW Canada',
    fni_prestige: 'FNI Prestige',
    stellantisDigital: 'Stellantis Digital',
    ringcentral: 'RingCentral',
    vbdc: 'VBDC',

    cdkCustomer: 'CDK - Customer',
    cdkRvService: 'CDK - Service',
    dealerVu: 'DealerVu',
    fcaDigital: 'Fiat Chrysler Canada',
    kiaDigital: 'Kia Canada',
    mbcaDigital: 'Mercedes Canada',
    nciDigital: 'Nissan-Infiniti Canada',
    oneEighty: 'One-Eighty',
    polestar: 'Polestar',
    porscheDigital: 'Porsche Canada',
    polarisDigital: 'Polaris',
    sciMarketviewSale: 'Taq Auto - Sale',
    sciMarketviewService: 'Taq Auto - Service',
    sertiRvService: 'Serti - Service',
    sertiVente: 'Serti - Sale',
    bmw_canada: 'BMW Canada',
    sertiRenewal: 'Serti - Renouvellement',
    fniPrestige: 'FNI Prestige',
    maseratiDigital: 'Maserati Digital',
    oneEightyInventory: 'One-Eighty - Inventaire',
    cdkInventory: 'CDK - Inventaire',
    sertiInventory: 'Serti - Inventaire',
    pbsInventory: 'PBS - Inventaire',
    easydealInventory: 'EasyDeal - Inventaire',
    mitsubishi: 'Mitsubishi',
    hyundai: 'Hyundai',
    deskitInventory: 'Deskit - Inventaire',
    deskit_inventory: 'Deskit - Inventaire',
    oneSourceInventory: 'One Source - Inventaire',
    onesourceInventory: 'One Source - Inventaire',
    one_source_inventory: 'One Source - Inventaire',
    lautopak_inventory: 'Lautopak - Inventaire',
    lautopakInventory: 'Lautopak - Inventaire',
    autoverify_inventory: 'Auto Verify - Inventaire',
    autoverifyInventory: 'Auto Verify - Inventaire',
    volkswagen: 'Volkswagen',
    ncar: 'NCAR',
    homenet_inventory: 'HomeNet - Inventaire',
    homenetInventory: 'HomeNet - Inventaire',
    tekion_inventory: 'Tekion - Inventory',
    tekionInventory: 'Tekion - Inventory',
    vauto_inventory: 'vAuto - Inventaire',
    vautoInventory: 'vAuto - Inventaire',
    dealer_vu_inventory: 'DealerVu - Inventaire',
    dealerVuInventory: 'DealerVu - Inventaire',

    hondaCanada: 'Honda Canada',
    acuraCanada: 'Acura Canada',
    honda_canada: 'Honda Canada',
    acura_canada: 'Acura Canada',
    cdkDrive: 'CDK Drive',
    cdk_drive: 'CDK Drive',
    canadianBlackBook: 'Canadian Black Book',
    autovance: 'AutoVance',
    tekion: 'Tekion',
    autosync: 'AutoSync',
    reynolds: 'Reynolds',

    export: {
        autovance: 'AutoVance',
        deskit: 'Deskit',
        lucy: 'Lucy',
        oneEighty: 'One-Eighty',
        sertiVente: 'Merlin',
        merlin: 'Merlin',
        pbs: 'PBS',
        autopropulsion: 'Autopropulsion',
        quicktrade: 'Torque',
        vauto: 'vAuto',
        fniPrestige: 'FNI Prestige',
        cdkDrive: 'CDK Drive',
        cdk_drive: 'CDK Drive',
        hyundai: 'VIN',
        volkswagen: 'VIN',
        tekion: 'Tekion',
        reynolds: 'Reynolds',
    },

    validation: {
        missingPhone: 'Numéro de téléphone valide requis.',
        missingEmail: 'Adresse courriel valide requise.',
        missingNames: 'Prénom et nom requis.',
        missingLastName: 'Nom de famille requis.',
        missingAllowedMileage: 'KM alloué est requis pour le type location.',
        missingPostalCode: 'Code Postal requis.',
        missingWantedVehicle: 'Le lead doit avoir un véhicule recherché.',
        missingDealType: 'Type de transaction est requis.',
        missingDealStatus: 'Statut de la transaction est requis.',
        missingModality: 'La modalité est requise.',
        missingFrequency: 'La Fréquence de paiement est requise.',
        missingTerm: 'Le terme est requis.',
        biMonthlyNotSupportByTekion: 'Bimensuel non supporté par Tekion',
        missingExchangeVin: 'Le NIV est requis pour un véhicule d\'échange.',
        missingWantedVinWhenStockEmpty: 'Le NIV est requis pour un véhicule recherché quand le # stock est manquant.',
    },

    tooltip: {
        doublesubmit: 'Une seule soumission est autorisée',
        mandatory: "Vous devez spécifier la marque, le modèle, l'année et le NIV afin de soumettre le véhicule",
        mandatoryOnlyVin: 'Vous devez spécifier le NIV afin de soumettre le véhicule',
        mandatoryWithOdometer: "Vous devez spécifier la marque, le modèle, l'année, l'odomètre et le NIV afin de soumettre le véhicule",
        received: 'Évaluation effectuée par {0} le {1}',
        valuesCannotBeNegatives: 'La valeur et le profit ne peuvent pas avoir une valeur négative.',
        validVin: 'NIV invalide. Un NIV valide est composé de 17 caractères.',
        pbsLastNameRequired: 'Le lead doit avoir un nom complet.',
        sertiUserRequired: 'Le lead doit avoir un conseiller d\'assigné.',
        cdkDriveExportDisabled: 'Le lead doit avoir un nom, prénom, un numéro de téléphone et un courriel valide et une addresse complete (incluant ville, province, pays et code postal)',
        leadNotFrom: 'Le lead doit provenir de {name}',
        leadAlreadyExported: 'Le lead a déjà été envoyé à {name}',
    },

    label: {
        unsubmitted: 'Non soumis',
        waiting: 'Évaluation soumise le {0}, en attente...',
        received: 'Évaluation reçue',
    },
};
